html, body, .presentation {
  background-color: rgba(0, 0, 0, 0);
}

.lobby {
  background-color: rgba(255, 255, 255, 0.8);
}

.presentation .player-box {
  background-color: rgba(255, 255, 255, 0.8);
}

.presentation .player-box .deck-name, .presentation .player-box .player-name, .presentation .player-box .player-lp {
  margin: 0;
}

.presentation .matches-count {
  margin-top: 2px;
}

.presentation .player-box .player-lp {
  color: rgba(0,0,0,.87);
}

.presentation .player-box .player-name {
  color: rgba(0,0,0,.87);
}

.presentation.screen .player-box {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 6px;
  left: 6px;
}

.presentation.small-screen .player-box {
  background-color: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 767px) {
  .menu > .container > .item > span {
    display: none;
  }
}
